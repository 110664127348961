/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 *
 *
 */


window.Vue = require('vue');
window.country = require('./json/country.json');
window.service = require('./json/service.json');
window.products = require('./json/products.json');

window.cities = [];
window.matieres = [];

window.type = [
    "Graines Oléogineuse",
    "Corps Gras",
    "Huile",
    "Noix",
    "Tourteaux",
    "Farine",
    "Coproduits"
];
window.recurrence = [
    "Hebdomadaire",
    "Mensuelle",
    "Bimensuelle",
    "Trimestrielle",
    "Semestrielle",
    "Annuelle"
];
window.emballage = [
    "Sac de tute",
    "Sac plastique",
    "Bouteille (1L)",
    "Bidon (25L)",
    "Füt"
];
window.genders = [
    {
        name: 'Genre',
        code: '-'
    },
    {
        name: 'Homme',
        code: 'H'
    },
    {
        name: 'Femme',
        code: 'F'
    }
];

window.maxDateYear = (new Date().getFullYear() - 18);


import Vue from 'vue'
import App from './components/App.vue'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {French} from 'flatpickr/dist/l10n/fr';
import {VMoney} from 'v-money';
import VueRatIt from 'vue-rate-it';



new Vue({
    el: '#app',
    components: { App, Multiselect, flatPickr, 'star-rating': VueRatIt.FaRating },
    directives: {money: VMoney},
    delimiters: ['${','}'],

    data: {
        genders: window.genders,
        selectedGender: window.gender || '-',
        types: window.type,
        countries: window.country,
        services: window.service,
        produits:window.products,
        recurrences:window.recurrence,
        emballages:window.emballage,
        selectedCountry: window.country || 'Pays',
        selectedProducts: window.products || 'Produit',
        selectedCity: window.cities,
        selectedMat: window.matieres || 'Matière première',
        countryCode: window.countryCode,
        cities: [],
        matieres:[],
        languages: [
            'Français', 'Anglais', 'Allemand', 'Arabe', 'Chinois mandarin',
            'Japonais','Russe','Espagnole','Latin','Portugais','hindi/ourdou',
            'Chinois wu','Coréen','Créole haïtien','Tchèque','Zoulou','Sylheti',
            'Chinois min bei','Kazakh','Akan','Deccan','Chewa (nyanja)','Grec',
            'Chhattisgarhi','Hongrois','Haryanvi','Magahi','Marwari','Somali',
            'Madourais','Assamais','Turkmène','Khmer','Chinois zhouang',
            'Chittagonien','Saraiki','Malgache','Kurde','Néerlandais',
            'Visayan (cibuano)','Chinois gan','Awadhi','Azéri','igbo (ibo)',
            'Oromo','Roumain','Peul (fulani)','Amharique','Sindhi','Ouzbek',
            'Maithili','Yorouba','Filipino (tagalog)','Bhojpouri','Ukrainien',
            'Chinois hakka','Birman','Oriya (odiya)','Soundanais','Malayalam',
            'Chinois xiang','Kannada','Pachtou','Polonais','Chinois min',
            'Chinois jing','Gujarati','Thaï','Persan','Italien','Tamoul',
            'Vietnamien','Turc','Marathi','Chinois cantonais','Malais',
            'Télougou','Javanais','Haoussa','Panjabi','Bengali'
        ],
        selectedLanguages: window.lstLng || null,
        selectedDomain: window.domain || '',
        inputDate: null,
        inputDateConfig: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'j M, Y',
            altInput: true,
            dateFormat: 'Y-m-d',
            locale: French,
            maxDate: new Date(window.maxDateYear, 11, 31)
        },
        proPrices: window.proPrices || {
            hour: 0,
            miday: 0,
            day: 0
        },
        proCurrency: window.proCurrency,
        moneyOpts: {
            decimal: ',',
            thousands: ' ',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false /* doesn't work with directive */
        }
    },
    methods: {
        changeCountry: function changeCountry() {
            var _this = this;

            var country = this.countries.find(function (e) {
                return e.name === _this.selectedCountry;
            });

            if (country) {
                this.cities = country.cities;
                this.countryCode = country.code;
                this.proCurrency = country.currency;
            }
        },
        changeCountry() {
            const country = this.countries.find(e => {
                return e.name === this.selectedCountry;
            });

            if (country) {
                this.cities = country.cities;
                this.countryCode = country.code;
                this.proCurrency = country.currency;
            }
        },
        changeProducts: function changeProducts(){
            var _this = this;
            var products = this.produits.find(function(e){
                return e.name === _this.selectedProducts;
            });
            
            if(products){
                this.matieres = products.matieres;
            }
        },
        changeProducts(){
            const products = this.produits.find(e =>{
                return e.name === this.selectedCountry;
            });

            if (products) {
                this.matieres = products.matieres;
            }
        }
    },

});


// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
